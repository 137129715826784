import axios, { AxiosError, AxiosResponse, AxiosInstance } from "axios";

let url =  'http://localhost:5000';
if (window.location.host === 'reliantcep.com' || window.location.host === 'www.reliantcep.com') {
   url = 'https://backend.reliantcep.com/'
}
if (window.location.host === 'stg-reliantcep.nrg.com' || window.location.host === 'https://stg-reliantcep.nrg.com') {
    url = 'https://stg-cep-backend.nrg.com/'
}


//console.log("The url is", window.location.host)

// Root url
axios.defaults.baseURL = url;

// Create an Axios instance that allows the backend to set cookies
const axiosWithCookieAuth = axios.create({
    withCredentials: true,
});

// Handle 400 & 500 errors
axiosWithCookieAuth.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

        if (!expectedError) {
            console.error(error);
        }

        return Promise.reject(error);
    }
);

// Request interceptors for API calls
axiosWithCookieAuth.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers || {};
        config.headers["token"] = `${sessionStorage.getItem("token")}`;
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);


// Create an Axios instance with the interceptors
const api: AxiosInstance = axios.create();
api.interceptors.request.use(
    (config: any) => {
        config.headers = config.headers || {};
        config.headers["token"] = `${sessionStorage.getItem("token")}`;
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

        if (!expectedError) {
            console.error(error);
        }

        return Promise.reject(error);
    }
);

// Set X Auth in outbound headers
function setJwt(jwt: string): void {
    api.defaults.headers.common["token"] = jwt;
}

// Create an object with the Axios methods and the setJwt function
const http = {
    get: axiosWithCookieAuth.get,
    post: axiosWithCookieAuth.post,
    put: axiosWithCookieAuth.put,
    delete: axiosWithCookieAuth.delete,
    setJwt,
};

// Export the http object as the default export
export default http;
